<template>
  <div class="main">
    <div class="title">
      <img src="./imgs/point.png" />
      <div>感谢你对树兰的信任</div>
      <img src="./imgs/point2.png" />
    </div>

    <van-field
      :class="showErr ? 'input input-border' : 'input'"
      v-model="jhm"
      maxlength="16"
      :border=false
      @input="didInput"
      :formatter="formatter"
      placeholder="请输入16位激活码"
    />

    <div class="err-msg">{{ showErr ? errMsg : "   " }}</div>

    <van-button
      :class="inputCompleted ? 'button button-active' : 'button'"
      size="large"
      @click="avtive"
      >提交</van-button
    >

    <div class="connect">
      如有疑问请联系
      <div @click="call" class="phone">{{ phone }}</div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { Field, Button, Toast } from "vant";
import Vue from "vue";
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
export default {
  components: {},
  data() {
    return {
      jhm: "",
      inputCompleted: false,
      showErr: false,
      errMsg: "",
      loading: true,
      phone: "15669951612",
    };
  },
  mounted() {},
  methods: {
    call() {
      window.location.href = `tel:${this.phone}`;
    },
    formatter(value) {
      // 过滤输入的数字
      return value.replace(/[^0-9|A-Z|a-z]/g, "");
    },
    didInput(e) {
      this.inputCompleted = e.length == 16;
      this.showErr = false;
      console.log(this.inputCompleted);
    },
    avtive() {
      if (!this.inputCompleted) {
        return;
      }
      Toast.loading({
        message: "正在激活...",
        forbidClick: true,
        duration: 0,
      });

      this.$axios
        .post(`/service/package/active/byCode?code=${this.jhm}`)
        .then((res) => {
          Toast.clear();
          console.log(res, "res");

          router.push({
            path: "/servicePackage/detail",
            query: {
              data: res.data,
            },
          });
        })
        .catch((err) => {
          console.log("err", err.response);
          if (err.response.status == 521) {
            this.showErr = true;
            this.errMsg = err.response.data.msg || "";
          }

          Toast.clear();
        });
    },
  },
};
</script>

<style lang="scss">
.main {
  // width: 750px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    margin: 70px 0 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #17847a;
    line-height: 45px;
    div {
      margin: 0 18px;
    }
    img {
      width: 69px;
      height: 30px;
    }
  }
  .input {
    width: 702px;
    height: 92px;
    background: #ffffff;
    border-radius: 14px;
    padding: 22px 28px;
    box-sizing: border-box;
    font-size: 34px;
  }

  .input-border {
    border: 1px solid #f23026;
  }
  .button {
    width: 670px;
    height: 94px;
    background: #cdcfd8;
    border-radius: 24px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
  }
  .button-active {
    background: #12beae;
  }
  .err-msg {
    margin: 14px 0 36px;
    width: 702px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ea3131;
    line-height: 40px;
    min-height: 40px;
  }
}

.van-cell::after {
  border: none;
}

.connect {
  margin-top: 60px;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4f5265;
  line-height: 42px;

  .phone {
    margin-left: 14px;
    float: right;
    color: #12beae;
    text-decoration: underline;
  }
}
</style>
